'use client';
import { useEffect } from "react";
import Link from "next/link";
export default function Error({ error, reset}:{error: Error & {digest?: string}, reset: () => void}){
  useEffect(() => {
    console.error(error)
  }, [error])
  const message = parseInt(error?.message) ? error?.message : "Hubo un problema";
  return(
    <div style={{textAlign: "center", padding:"24px"}}>
      <h1 style={{marginBottom: "24px"}}>Error: {message}</h1>
      <Link style={{border:"1px solid black", padding:"4px 8px"}} href="/">Regresar al contenido</Link>
    </div>
  );
}